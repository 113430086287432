import { Link } from '@instructure/ui-link';
import { Tooltip } from '@instructure/ui-tooltip';
import I18n from 'i18n-js';
import get from 'lodash/get';
import React, { FunctionComponent, Fragment, ReactNode } from 'react';

import { Job, RosterImport } from '../redux/job';
import { Schedule } from '../redux/schedule';

export const JOB_TYPES: { [key: string]: string } = {
  rawData: I18n.t('Raw Data'),
  rostering: I18n.t('Roster Import'),
  rosterCompare: I18n.t('Roster Compare'),
  remapSisIds: I18n.t('Remap SIS IDs'),
  assignment: I18n.t('Grade Passback - Course'),
  gpbAccount: I18n.t('Grade Passback - Account'),
  accountReport: I18n.t('Account Report'),
  grade: I18n.t('Account Report - Grade Export'),
  assignmentGPBAccount: I18n.t('Account Report - SIS Submissions'),
  onerosterCsvGpb: I18n.t('Account Report - SIS Submissions - OneRoster'),
  kimonoAssignmentGPB: I18n.t('Kimono Grade Passback'),
  kimonoCumulativeGPB: I18n.t('Kimono Cumulative Grade Passback'),
  kimonoCategoryExchange: I18n.t('Kimono Category Exchange'),
  pauseAgents: I18n.t('Pause Agents'),
  chores: I18n.t('Chores'),
  resetIntegrationData: I18n.t('Reset Integration Data'),
  disableSyncToSis: I18n.t('Disable Sync to SIS'),
  rosterFetchVerification: I18n.t('Verify rostering snapshot'),
};

export const LTI_JOB_TYPES = {
  ...JOB_TYPES,
  kimonoAssignmentGPB: I18n.t('Grade Sync'),
  kimonoCumulativeGPB: I18n.t('Grade Sync'),
  kimonoCategoryExchange: I18n.t('Category Sync'),
};

type Props = {
  job: Job | Schedule;
  prefix?: string;
  isLTI?: boolean;
};

enum ResourceLinkKey {
  SisImport = 'sis_imports',
  Course = 'courses',
}

const renderResourceLink =
  (instanceURL: string, resourceKey: ResourceLinkKey) =>
  (resourceId: string): ReactNode => (
    <Link
      key={resourceId}
      href={`${instanceURL}/${resourceKey}/${resourceId}`}
      target="_blank"
      isWithinText={false}
    >
      {resourceId}
    </Link>
  );

const renderResourceLinkArray = (
  input: Array<string>,
  linkGenerator: (id: string) => ReactNode,
): ReactNode => {
  return input
    .map((inputId: string) => linkGenerator(inputId))
    .reduce((prev: ReactNode | Array<ReactNode>, curr: ReactNode) =>
      Array.isArray(prev) ? [...prev, ', ', curr] : [prev, ', ', curr],
    );
};

export const COURSE_IDS_DISPLAY_LIMIT = 35;

const JobName: FunctionComponent<Props> = (props: Props) => {
  const { job, prefix = '', isLTI = false } = props;
  const type = isLTI ? get(LTI_JOB_TYPES, job.type, job.type) : get(JOB_TYPES, job.type, job.type);
  const instanceURL = get(job, 'data.instanceURL');
  const rosterImports = get(job, 'data.rosterImports', []);
  const sisImportIds = Array.isArray(rosterImports)
    ? rosterImports
        .map((sisImport: RosterImport) => get(sisImport, 'state.sisImportId'))
        .filter(Boolean)
    : [];
  const courseId = get(job, 'data.courseId');
  const courseIds: Array<string> = get(job, 'data.courseIds', []);
  const name = get(job, 'data.name');
  const notes = get(job, 'data.notes');
  const words = [];

  if (prefix) {
    words.push(prefix);
  }
  if (name) {
    words.push(name);
  }
  words.push(type);
  if (job.data.rosteringMode === 'spawn_job_per_school') {
    words.push(I18n.t('Spawn Job Per School'));
  }

  if (sisImportIds.length) {
    if (instanceURL) {
      return (
        <Fragment>
          {words.join(' - ')}
          {' - '}
          {renderResourceLinkArray(
            sisImportIds,
            renderResourceLink(instanceURL, ResourceLinkKey.SisImport),
          )}
        </Fragment>
      );
    } else {
      words.push(sisImportIds.join(', '));
    }
  }

  if (courseIds.length) {
    let displayCourseIds = courseIds;
    let moreCoursesText = '';

    if (COURSE_IDS_DISPLAY_LIMIT < courseIds.length) {
      displayCourseIds = courseIds.slice(0, COURSE_IDS_DISPLAY_LIMIT);
      moreCoursesText = I18n.t(' ...{{count}} more', {
        count: courseIds.length - displayCourseIds.length,
      });
    }

    if (instanceURL) {
      return (
        <Fragment>
          {words.join(' - ')}
          {' - '}
          {renderResourceLinkArray(
            displayCourseIds,
            renderResourceLink(instanceURL, ResourceLinkKey.Course),
          )}
          {moreCoursesText}
        </Fragment>
      );
    } else {
      words.push(displayCourseIds.join(', ') + moreCoursesText);
    }
  }

  if (courseId) {
    if (instanceURL) {
      return (
        <Fragment>
          {words.join(' - ')}
          {' - '}
          {renderResourceLink(instanceURL, ResourceLinkKey.Course)(courseId)}
        </Fragment>
      );
    } else {
      words.push(courseId);
    }
  }

  if (!isLTI && notes && notes !== '') {
    return (
      <Fragment>
        <Tooltip renderTip={notes}>{words.join(' - ')}</Tooltip>
      </Fragment>
    );
  }

  return <Fragment>{words.join(' - ')}</Fragment>;
};

export default JobName;
